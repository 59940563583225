import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "../logo.png";
import "./Dashboard2.css";
import { useAppContext } from "./context/AppContext";
import swal from "sweetalert";

const Dashboard = (props) => {
  const { url, setUrl, userId, setUserId, assignmentId } = useAppContext();
  const urlMain = "https://24livehosts.com/rootyapplication/images";

  const history = useHistory();

  function onAccept() {
    history.push("/thankyou");
  }
  function handleKeyDown(event) {
    event.preventDefault();
    gotofullscreen();
  }
  document.addEventListener("DOMContentLoaded", () => {
    document.addEventListener("keydown", handleKeyDown);
  });

  document.addEventListener("fullscreenchange", function () {
    if (document.fullscreenElement) {
      console.log("full screen start");
    } else {
      swal({
        title: "Do you want to go back?",
        icon: "warning",
        buttons: {
          cancel: "No",
          confirm: "Yes",
        },
      }).then((isConfirmed) => {
        if (isConfirmed) {
          gotofullscreen();
        } else {
          gotofullscreen();
        }
      });
    }
  });
  function gotofullscreen() {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  const [assignments, setAssignments] = useState([]);
  const [userName, setUserName] = useState([]);
  const [userEmail, setuserEmail] = useState([]);
  const [userPhoneNumber, setuserPhoneNumber] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formsave, setformsave] = useState(false);

  useEffect(() => {
    fetch(
      `https://24livehosts.com/rootyapplication/api/assignment/${userId}/${assignmentId}`
    )
      .then((response) => response.json())
      .then((data1) => {
        setAssignments(data1.data.assignment);
        setUserName(data1.data.user.name ? data1.data.user.name : "");
        setuserEmail(data1.data.user.email ? data1.data.user.email : "");
        setuserPhoneNumber(
          data1.data.user.phoneNumber ? data1.data.user.phoneNumber : ""
        );
        console.log(userId + "hi");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const [formData, setFormData] = useState({
    assignment_id: assignmentId,
    user_id: userId,
    answer: [],
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    let isFormValid = true;
    const updatedFormData = assignments.map((element) => {
      let valueofquestion = "";

      if (element.question_type === 1) {
        const radioInput = document.querySelector(`input[name="${element.id}"]:checked`);
        if (radioInput) {
          valueofquestion = radioInput.value;
        } else {
          isFormValid = false;
        }
      } else {
        valueofquestion = document.getElementById(element.id).value.trim();
        if (valueofquestion === "" && element.question_type !== 1) {
          isFormValid = false;
        }
      }

      return {
        question_id: element.id,
        value: valueofquestion,
        image: "a.png",
        description: valueofquestion,
      };
    });

    if (isFormValid) {
      setFormData((prevData) => ({
        ...prevData,
        answer: updatedFormData, // Just set the updatedFormData
      }));
      setformsave(true);

      // Navigate to the "thank you" page (replace '/thankyou' with the actual route)
      // history.push('/thankyou');
    } else {
      // Show a "required" message or handle validation errors
      window.alert('Please fill in all required fields.');
    }
  };
  useEffect(() => {

    console.log("newdataa",formData);
    if (formsave) {
      fetch(
        "https://24livehosts.com/rootyapplication/api/complete_web_assignment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      )
        .then((response) => {
          if (!response.ok) {
            swal({
              title: "Sorry! There was an problem. Please try again !",
              icon: "warning",
              buttons: {
                confirm: "Okay",
              },
            }).then((isConfirmed) => {
              history.push("/exit");
            });
          } else {
            onAccept();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [formsave]);

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="dashQuiz" id="Dash">
          <div className="dashQuizContainer">
            <div className="row">
              <div className="col-sm-6">
                <div className="camContiner">
                  <div className="candidateDetail">
                    <div
                      className="lame"
                      style={{ marginLeft: "200px", marginTop: "240px" }}
                    >
                      <h3 align="left">
                        <label>Name :</label> <span> {userName}</span>
                      </h3>
                      <h3 align="left">
                        <label>Email :</label> <span> {userEmail}</span>
                      </h3>
                      <h3 align="left">
                        <label>Phone Number :</label>{" "}
                        <span> {userPhoneNumber}</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="dashBottom">
                  <div className="button">
                    <p align="center" style={{ marginLeft: "250px" }}>
                      <img src={logo} alt="logo" className="logo" />
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <form onSubmit={handleSubmit}>
                    {assignments &&
                      assignments.map((assignment, index) => (
                        <div
                          key={index}
                          style={{
                            border: "1px solid #dddddd",
                            borderRadius: 20,
                            margin: 10,
                            padding: 20,
                            minWidth: 400,
                          }}
                        >
                          <div className="col-12 px-0">
                            <p>
                              Q. {index + 1}. {assignment.question_name}
                            </p>
                          </div>
                          {assignment.question_type === 1 ? (
                            <div key={assignment.id} className="col-12">
                              {assignment.question_image ? (
                                <div>
                                  <img alt="img"
                                    src={
                                      urlMain + "/" + assignment.question_image
                                    }
                                    width="150px"
                                    height="150px"
                                  />
                                </div>
                              ) : (
                                ""
                              )}

                              <div>
                                <input
                                  type="radio"
                                  name={assignment.id}
                                  id="radioExample1"
                                  className="mr-1"
                                  value="A"
                                />
                                <label htmlFor="radioExample1">
                                  {assignment.option_one}
                                </label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name={assignment.id}
                                  id="radioExample2"
                                  className="mr-1"
                                  value="B"
                                />
                                <label htmlFor="radioExample2">
                                  {assignment.option_two}
                                </label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name={assignment.id}
                                  id="radioExample3"
                                  className="mr-1"
                                  value="C"
                                />
                                <label htmlFor="radioExample3">
                                  {assignment.option_three}
                                </label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name={assignment.id}
                                  id="radioExample4"
                                  value="D"
                                  className="mr-1"
                                />
                                <label htmlFor="radioExample4">
                                  {assignment.option_four}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <div className="col-12">
                              {assignment.question_image ? (
                                <div>
                                  <img alt="img"
                                    src={
                                      urlMain + "/" + assignment.question_image
                                    }
                                    width="150px"
                                    height="150px"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <textarea
                                id={assignment.id}
                                className="mt-3"
                              ></textarea>
                            </div>
                          )}
                        </div>
                      ))}
                    <div
                      style={{
                        borderRadius: 20,
                        margin: 10,
                      }}
                    >
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
