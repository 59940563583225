import { Button } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import "./SystemCheck.css";
import icon from "./icon.png";
import DetectRTC from "detectrtc";
import swal from "sweetalert";

import logo from "../logo.png";
import SystemGraphis from "../images/system-compability.png";

const NetworkSpeed = require("network-speed"); // ES5
const testNetworkSpeed = new NetworkSpeed();

var buttonViewDisabled = true;

function ValidateCheck() {
  var isAllowed = false;

  // Network Check
  var netSpeedVar = sessionStorage.getItem("netspeed");
  if (netSpeedVar > 2) {
    isAllowed = true;
  }

  // Browser Check
  if (DetectRTC.browser.isChrome) {
    //.................................Chrome
    // If Browser is Chrome
    if (DetectRTC.browser.version > 80) {
      // If the Browser is updated
      isAllowed = true;
    } else {
      // If browser is not Updated
      swal("Please Update Browser or Try a Different Browser");
      isAllowed = false;
    }
  }
  if (DetectRTC.browser.isFirefox) {
    //.................................Firefox
    // If Browser is Chrome
    if (DetectRTC.browser.version > 60) {
      // If the Browser is updated
      isAllowed = true;
    } else {
      // If browser is not Updated
      swal("Please Update Browser or Try a Different Browser");
      isAllowed = false;
    }
  }
  if (DetectRTC.browser.isSafari) {
    //.................................Safari
    // If Browser is Chrome
    if (DetectRTC.browser.version > 12) {
      // If the Browser is updated
      isAllowed = true;
    } else {
      // If browser is not Updated
      swal("Please Update Browser or Try a Different Browser");
      isAllowed = false;
    }
  }
  if (DetectRTC.browser.isOpera) {
    //.................................Opera
    // If Browser is Chrome
    if (DetectRTC.browser.version > 60) {
      // If the Browser is updated
      isAllowed = true;
    } else {
      // If browser is not Updated
      swal("Please Update Browser or Try a Different Browser");
      isAllowed = false;
    }
  }
  if (DetectRTC.browser.isEdge) {
    //.................................Edge
    // If Browser is Chrome
    if (DetectRTC.browser.version > 80) {
      // If the Browser is updated
      isAllowed = true;
    } else {
      // If browser is not Updated
      swal("Please Update Browser or Try a Different Browser");
      isAllowed = false;
    }
  }

  // Final Approval
  if (isAllowed) {
    buttonViewDisabled = false;
  } else {
    buttonViewDisabled = true;
  }
}

const SystemCheck = () => {
  // Network Speed Check
  async function getNetworkDownloadSpeed() {
    const baseUrl = "https://eu.httpbin.org/stream-bytes/500000";
    const fileSizeInBytes = 500000;
    const speed = await testNetworkSpeed.checkDownloadSpeed(
      baseUrl,
      fileSizeInBytes
    );
    return speed;
  }
  getNetworkDownloadSpeed().then((value) => {
    sessionStorage.setItem("netspeed", value["mbps"]);
  });

  // System Detections Package
  var DetectRTC = require("detectrtc");

  ValidateCheck();

  var elem = document.documentElement;

  function openFullscreen() {
	  history.push("/dashboard");
  }
  const history = useHistory();

  return (
    <>
      <header className="App-header">
        <img src={logo} alt="logo" className="logo" />
        <p>Welcome to Rooty</p>
      </header>
      <div className="main">
        <img src={SystemGraphis} />
        <p className="sign" align="center">
          System Compatibility Check
        </p>

        <div className="row pb-4">
          <div className="col-sm-5">
            <img src={icon} id="classIcon" />
          </div>
          <div className="col-sm-7">
            <ul>
              <li className="test">
                <span>
                  <b>OS:</b>{" "}
                  {"- " +
                    JSON.stringify(DetectRTC.osName, null, 2).slice(1, -1) +
                    " " +
                    JSON.stringify(DetectRTC.osVersion, null, 0).slice(
                      1,
                      -1
                    )}{" "}
                </span>
              </li>
              <li className="test">
                <span>
                  <b>Browser:</b>{" "}
                  {"- " +
                    JSON.stringify(DetectRTC.browser.name).slice(1, -1) +
                    " " +
                    JSON.stringify(DetectRTC.browser.version)}{" "}
                </span>
              </li>
              <li className="test">
                <span>
                  <b>Internet Speed:</b>{" "}
                  {"- " + sessionStorage.getItem("netspeed") + " mbps"}{" "}
                </span>
              </li>
              <li className="test">
                <span>
                  <b>Webcam:</b>{" "}
                  {"- " +
                    JSON.stringify(
                      DetectRTC.isWebsiteHasWebcamPermissions
                    )}{" "}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <center>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={openFullscreen}
          >
            Next
          </Button>
        </center>
      </div>
    </>
  );
};

export default SystemCheck;
