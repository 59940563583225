import Button from "@material-ui/core/Button";

const Exit = () => {
	function handleClickExit() {
		window.close();
	}
	return (
		<div className="thankyouPage">
		<div className="container">
			<center>
				<Button variant="contained" className="header-button" onClick={handleClickExit}>
					Exit Secure Window
				</Button>
			</center>
		</div>
		</div>
	);
};
export default Exit;
