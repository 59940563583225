import firebase from "firebase";
import "firebase/auth";

// const config = firebase.default.initializeApp({
//     apiKey: "AIzaSyA_kq7aSBICUn42RB3VPnPnrpZtCLNvtAs",
//     authDomain: "god-s-eye-b94dc.firebaseapp.com",
//     databaseURL: "https://god-s-eye-b94dc-default-rtdb.firebaseio.com",
//     projectId: "god-s-eye-b94dc",
//     storageBucket: "god-s-eye-b94dc.appspot.com",
//     messagingSenderId: "87925896858",
//     appId: "1:87925896858:web:299a3e05e7850651fb1123",
//     measurementId: "G-126PWQ4RL1"
// });
const config = firebase.default.initializeApp({
	apiKey: "AIzaSyD81w3I6IiANCkpjBDKIOJBuk95H_xFQgM",
	authDomain: "online-interview-1ac32.firebaseapp.com",
	databaseURL: "https://online-interview-1ac32-default-rtdb.firebaseio.com",
	projectId: "online-interview-1ac32",
	storageBucket: "online-interview-1ac32.appspot.com",
	messagingSenderId: "456770373217",
	appId: "1:456770373217:web:cf2c346ab0be3244938c20",
	measurementId: "G-G61ESH7W75",
});

export const auth = firebase.auth();
export default config;
