import config from "../config";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import swal from "sweetalert";
import "./Results.css";
import logo from "../logo.png";

require("firebase/auth");
const AdminSignIn = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;
    try {
      config
        .auth()
        .signInWithEmailAndPassword(email.value, password.value)
        .then((u) => {
          setCurrentUser(true);
        })
        .catch((error) => {
          swal("Please Contact Admin To Register");
        });
    } catch (error) {
      alert(error);
    }
  };

  if (currentUser) {
    return <Redirect to="/admin" />;
  }

  return (
    <div>
      <header className="App-header">
        <img src={logo} alt="logo" className="logo" />
        <p>Welcome to Rooty</p>
        <small>AI Enabled Virtual Examination System</small>
      </header>
      <div className="adminsignin-wrapper">
        <h1>Sign In</h1>
        <br />
        <form className="admin-login-form" onSubmit={handleSubmit}>
          <label for="email">Email: </label>
          <br />
          <input
            className="form-control"
            type="email"
            name="email"
            placeholder="Email"
          />
          <br />
          <label for="password">Password: </label>
          <br />
          <input
            className="form-control"
            type="password"
            name="password"
            placeholder="Password"
          />
          <br />
          <br />
          <input
            class="MuiButtonBase-root MuiButton-root MuiButton-contained header-button center-block"
            type="submit"
            value="Submit"
          />
        </form>
      </div>
    </div>
  );
};

export default AdminSignIn;
