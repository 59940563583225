import React,{useRef,useEffect} from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import logo from "./../logo.png";
import auth from "./Auth";

const FullScreenAlert = (props) => {

  const history = useHistory();
  let back2examCalled = false;

  function disableEscKey(event) {
    console.log("object",event);
    if (event.key === "Escape" || event.keyCode === 27) {
      if (back2examCalled) {
        event.preventDefault();
      }
    }
  }

  document.addEventListener("keydown", disableEscKey);

  function back2exam() {
    const element = document.documentElement;
    document.addEventListener("keydown", disableEscKey);

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }

    auth.login(() => {
      history.push("/dashboard");
    });
    back2examCalled = true; 
  }

  return (
    <div className="alert-screen">
      <header className="App-header">
        <img src={logo} alt="logo" className="logo" />
        <p>Welcome to Rooty</p>
      </header>

      <div className="container">
        <center>
          <h3>Alert!</h3>
          <br />
          <small>If you try to switch the tab or try to exit the screen</small>
          <br />
          <br />
          <p>
            <i>We will mark your assignment as not done and cheated</i>
          </p>

          <Button
            variant="contained"
            onClick={back2exam}
            className="header-button"
          >
            I Understand
          </Button>
        </center>
      </div>
    </div>
  );
};

export default FullScreenAlert;
