import { createContext, useContext, useState, useEffect } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  // Initialize state with default values.
  const [userId, setUserId] = useState('');
  const [assignmentId, setAssignmentId] = useState('');

  useEffect(() => {
    // Check if we're in a browser environment before accessing window.location.
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      const initialUserId = searchParams.get("user_id") || '';
      const initialAssignmentId = searchParams.get("assignment_id") || '';

      // Set the state based on URL parameters.
      setUserId(initialUserId);
      setAssignmentId(initialAssignmentId);

    }
  }, []);

  const contextValue = {
    userId,
    setUserId,
    assignmentId,
    setAssignmentId,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};
